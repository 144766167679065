<template>
  <BlogSummary :list-site="listSite"></BlogSummary>
</template>

<script>
import BlogSummary from "@/components/index/BlogSummary";

export default {
  name: "Home",
  components: {
    BlogSummary
  },
  data(){
    return{
      listSite: "/blog/summary",
    }
  }
}
</script>

<style scoped>

</style>